
body {
  cursor: default;
}

.break {
  flex-basis: 100%;
}

#immersionPlayList {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.immersionHomeMessag{
  margin: 30px;
}

.immersionPlayWrapper{
  display: flex;
  background: rgb(242, 242, 242);
  border: 1px solid #ccc;
  box-shadow: 1px 1px 2px #fff inset,
              -1px -1px 2px #fff inset;
  border-radius: 3px/6px;
  padding: 2%;       
  margin: 5%;
  height: 20%;
  text-align: left;
}

.immersionPlay {
  display: inline-block;
  width: 100%;
}

#specificPlayInfo {
  display: none;
}

#specificPlayRoles{
  display: none;
}

#specificPlayRoles {
  display: none;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.characterBox{
  display: flex;
  background: #F2F2F2;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 2px #fff inset,
              -1px -1px 2px #fff inset;
  border-radius: 3px/6px;
  padding: 2%;       
  margin: 20px;
  height: 20%;
  text-align: left;
}

#momentControls {
  display: none;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

p {
  font-size: 1.3rem;
}

.buttonWrap {
  display: flex;
  background: #F2F2F2;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 2px #fff inset,
              -1px -1px 2px #fff inset;
  border-radius: 3px/6px;
  padding: 2%;       
  margin: 20px;
  height: 100px;
  width: 100px;
  text-align: left;
}

input[type=button], input[type=submit], input[type=reset], button{
  background-color: #0084ff;
  border: none;
  color: white;
  padding: 16px 32px;
  text-decoration: none;
  border-radius: 10px;
  margin: 4px 2px;
  cursor: pointer;
}


.fullRow {
  display: inline-block;
  width: 100%;
  height: 200px;
  text-align: center;
}

.reactRoot{
  width: 100%;
  height: 100%;
}

.allCategoryWrapper{
  display: relative;
}

.playCategory {
  display: flex;
  flex-wrap: wrap;
  background: #F2F2F2;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 2px #fff inset,
              -1px -1px 2px #fff inset;
  border-radius: 3px/6px;
  padding: 2%;       
  margin: 20px;
  text-align: left;
  max-height: 20%;
}

.playCategoryTitle{
  font-size: 2.5rem;
  margin-bottom: 0;
}

.playCategoryTitleWrapper {
  width: 100%;
  height: 5%;
}

.coverSet{ 
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.playCover {
  margin: 2%;       
  min-width: 10%;
  max-width: 29%;
  flex: 8;
  margin-top: 0;
}

#playSelectionHeader{
  padding-left: 5%;
  padding-right: 10%;
}

.playDescriptionScreen{
  padding-top: 5%;
  padding-left: 5%;
  padding-right: 10%;
}

.playDescription img{
  max-height: 300px;
}


#performanceSpaceSetup{
  padding-top: 20px;
  padding-right: 10%;
}

.roleDescription {
  display: relative;
  background: #1034E6;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 2px #fff inset,
              -1px -1px 2px #fff inset;
  border-radius: 6px/12px;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 20px;
  margin-right: 10px; 
  text-align: left;
  color: white;
  white-space:nowrap;
}

.roleDescriptionSelected {
  display: relative;
  background: #05A9F2;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 2px #fff inset,
              -1px -1px 2px #fff inset;
  border-radius: 6px/12px;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 20px;
  margin-right: 10px; 
  text-align: left;
  color: white;
  white-space:nowrap;
}

.roleDescriptionOther {
  display: relative;
  background: #555;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 2px #fff inset,
              -1px -1px 2px #fff inset;
  border-radius: 6px/12px;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 20px;
  margin-right: 10px; 
  text-align: left;
  color: white;
  white-space:nowrap;
}

.rolesReady{
  display: relative;
  background: #000000;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 2px #fff inset,
              -1px -1px 2px #fff inset;
  border-radius: 6px/12px;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-right: 10px; 
  text-align: left;
  color: white;
  white-space:nowrap;
}

.roleDescription p{
  color: white;
}

.role-description-width{
  width:100%;
  padding-right: 0;
}

.roleList{
  display: flex;
  flex-wrap: wrap;
  background: #F2F2F2;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 2px #fff inset,
              -1px -1px 2px #fff inset;
  border-radius: 3px/6px;
  padding: 2%;       
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: left;
}

.immersion-welcome-placeholder{
  height: 10000px;  
}

.selectionTransition-enter {
  opacity: 0;
  transform: scale(0.9);
}
.selectionTransition-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.selectionTransition-exit {
  opacity: 1;
}
.selectionTransition-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.selectionTransition-exit-done {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.playbackUI{
  padding:0;
}



.blank-screen-waiting{
  text-align: center;
  height: 400px;
  padding: 0;
  background-color: #555555
}

.dialogue-instructions{
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  align-items: center;
  flex-flow: row wrap;
}

.playback-instructions-pading{
  padding-top: 20px;
  padding-left: 5%;
  padding-right: 10%;
}

.end-play-moment-link{
  color: #0084ff;
}

.end-play-moment-link a, a:link, a:visited, a:active{
  color: #0084ff;
}

.disconnectScreen{
  font-size: 1.5rem;
  padding-top: 3%;
  padding-bottom: 3%;
  padding-right: 10%;
  padding-left: 10%;
}

.playbackRateAdjustWrapper{
  margin-left: 42%;
  display: flex;
  width: 15%;
  white-space: nowrap;
}

.playbackSpacer{
  width: 4%;
}

.actorReadingWrapper{
  display: flex;
}

.readingCenter{
  flex:8;
}

.readingBackButton{
  flex:1;
  padding-top: 30px;
  padding-bottom: 30px;
}

.readingForwardButton{
  flex:1;
  padding-top: 30px;
  padding-bottom: 30px;
}

#endMomentText{
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 5%;
}

pre{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.4rem;
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.readingNavigation{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
}

.navEntry{
  margin-right: 5px;
}

.navAct{
  font-size: 1.4rem;
}

.navMoment{
  font-size: 1rem;
}