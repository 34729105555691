.blog-base{
  display: flex;
  flex-basis: 100%;
}

.buffer{
  flex: 1
}

.blog-placeholder{
  height: 10000px;  
}

.blog-center{
  flex: 4
}
.blog-post{
  flex: 7;
  margin-top: 2%;
  margin-bottom: 2%;
}

.title{
  text-align: center;
  padding-top: 3%;
}

.no-margin{
  margin-bottom: 0;
}

.blogPreviewEntry{
  outline-style: solid;
  padding: 10px;
  margin-bottom: 20px;
  outline-color: gray;
}

.blogPreviewEntry p{
  color: black;
}