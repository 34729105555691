
.app-base {
}

.menu-bar {
  flex: 1;
  padding: 10px;
  min-height: 80px;
  background: #000 !important;
  overflow: hidden;
}

.menu-logo{
  width: 126;
  height: 48;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;

}

.menu-link-wrapper{
  width: 33%;
}

.links{
  padding-top: 1.5%;
  overflow-wrap: break-word;
}

.menu-item{
  color: white;
  padding-right: 1rem;
  font-size: 1.3rem;
}

a:visited {
  color: #fff
}

a:hover, a:active{
  color: #0084ff;
}

.landing-page-base{
  display: flex;
  flex-wrap: wrap;
}

.splash-section{
  display: flex;
  flex-wrap: nowrap;
  flex: 1;
  background-color: #333333;
  height: 50%;
  padding-top: 10%;
  padding-bottom: 10%;
}

.immersion-image{
  max-width: 80%;
}


.tag-line {
  padding-top: 10%;
  color: #0084ff;
  font-size: 2rem;
}

.immersion-tag-line {
  padding-top: 5%;
  color: #0084ff;
  font-size: 2rem;
}

.big-tag-line{
  color: #fff;
}

.logo-image{
  padding-top: 8%;
  padding-bottom: 3%;
  width: 50%;
}

.splash-summary{
  padding-top: 2%;
  padding-bottom: 4%;
}

.break {
  flex-basis: 100%;
}

.splash-content{
  flex: 4;
}

.immersion-promo-section{
  display: flex;
  flex: 1;
  flex-basis: 100%;
  background-color: #000;
  padding-top: 10%;
  padding-bottom: 10%;
}

.landing-splash-buffer{
  flex: 1
}

.immersion-splash-buffer{
  flex: 1
}

.immersion-content{
  flex: 4;
}

.immersion-description{
  color: #fff;
}

.immersion-subtitle{
  padding-top: 3%;
  color: #999;
}

.big-seperator {
  height: 5px;
  width: 40px;
  background-color: #fff;
  border-radius: 50px;
}

.skinny-seperator {
  height: 1px;
  width: 50%;
  background-color: #fff;
  border-radius: 50px;
}

.footer {
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 2%;
  width: 100%;
  background: #000 !important;
  text-align: center;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}